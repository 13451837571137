import React from 'react'

const BrandHome = () => {
    return (
        <>

            <h1 >Dashboard home</h1>

        </>
    )
}

export default BrandHome