// :: Auth Form
// :: Auth Events

export const STORE_LOCAL_DATA = 'STORE_LOCAL_DATA';
export const GET_LOCAL_DATA = 'GET_LOCAL_DATA';
export const REMOVE_LOCAL_DATA = 'REMOVE_LOCAL_DATA'
export const CLEAR_LOCAL_DATA = 'CLEAR_LOCAL_DATA'
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA'

export const IS_SESSION_ACTIVE = 'IS_SESSION_ACTIVE';

export const API_ERROR = 'API_ERROR';
export const API_CLOSE = 'API_CLOSE';
export const API_REQUEST = 'API_REQUEST';

export const LOAD_CACHE_DATA = 'LOAD_CACHE_DATA';
export const GET_CACHE_DATA = 'GET_CACHE_DATA';
export const CLEAR_CACHE_DATA = 'CLEAR_CACHE_DATA';