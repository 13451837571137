import React from 'react'

const BrandWishlist = () => {
   
    return (
        <div>

         

        </div>
    )
}

export default BrandWishlist;